import React, { Suspense, lazy, useEffect, useState } from "react";
import { Switch, Route } from "react-router-dom";
import { BrowserRouter } from "react-router-dom";
import Helmet from "react-helmet";

// import Home from '../views/home';
// import Invoice from '../views/invoice'
// import PaymentLink from '../views/paymentLink';
const Home = lazy(() => import("../views/home"));
const Invoice = lazy(() => import("../views/invoice"));
const PaymentLink = lazy(() => import("../views/paymentLink"));

const Routes = () => (
  <div>
    <Helmet
      title={process.env.REACT_APP_TITLE}
      meta={[
        {
          name: "description",
          content: process.env.REACT_APP_DESCRIPTION,
        },
      ]}
      link={[
        { rel: "icon", type: "image/png", href: "https://ik.imagekit.io/SeerBit/sabi/Sabi_Crown_Element_Zw_lbi4xPl.png" },
      ]}
    />
    <Suspense fallback={<div></div>}>
      <BrowserRouter>
        {/* <main> */}
        <Switch>
          <Route exact path="/" component={Home} />
          <Route exact path="/invoice" component={Invoice} />
          <Route path="*" component={PaymentLink} />
        </Switch>
        {/* </main> */}
      </BrowserRouter>
    </Suspense>
  </div>
);

export default Routes;
